module.exports = [{
      plugin: require('/codebuild/output/src154922602/src/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-141793947-1"],"gtagConfig":{"optimize_id":"GTM-5F9N8WP","anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('/codebuild/output/src154922602/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ishaqinc.com"},
    },{
      plugin: require('/codebuild/output/src154922602/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
